
import moment from 'moment';



// Utility functions
export function checkProperty(object = null, mainKey = '', subKey = '', returnValue = '') {
  //alert(object)
  if (object != null && mainKey != '') {
    if (object.hasOwnProperty(mainKey)) {
      if (subKey != '') {
        if (object[mainKey].hasOwnProperty(subKey)) {
          returnValue = object[mainKey][subKey];
          if (subKey == 'countryCallingCode' && returnValue) {

            if (!(returnValue.includes("+"))) {
              returnValue = "+" + returnValue
            }
          }
          return returnValue;
        } else {
          return returnValue;
        }
      } else {
        return object[mainKey];
      }
    } else {
      return returnValue;
    }
  } else {
    return returnValue;
  }
}

export function getUserRole() {
  let userInfoStr = localStorage.getItem("userinfo");
  if (userInfoStr) {
    let userData = JSON.parse(userInfoStr)
    if (userData && userData.roleId) {
      return userData.roleId
    }
  }
  return null
}
export function getUserId() {
  let userInfoStr = localStorage.getItem("userinfo");
  if (userInfoStr) {
    let userData = JSON.parse(userInfoStr)
    if (userData && userData.userId) {
      return userData.userId
    }
  }
  return null
}

export function getLoginDetailsId() {
  let userInfoStr = localStorage.getItem("userinfo");
  if (userInfoStr) {
    let userData = JSON.parse(userInfoStr)
    if (userData && userData.detailsId) {
      return userData.detailsId
    }
  }
  return null
}


export function checkProfileCompleted() {
  let userInfoStr = localStorage.getItem("userinfo");
  if (userInfoStr) {
    let userData = JSON.parse(userInfoStr)
    if (userData && userData.isProfileCompleted) {
      return userData.isProfileCompleted
    }
  }

  return false
}
export function isEmpty(value) {
  if (value !== undefined && value !== null) {
    if (typeof (value) == 'string') {
      if (value.toString().trim() !== '' && value.toString().trim() !== 'null' && value.toString().trim() !== "") {
        return false
      }
    } else {
      if (value !== '' && value !== 'null' && value !== "")
        return false;
    }

  }
  return true;
}
export function getUsAddress(object, showLineZip=true) {
  var address = '';
  if (!isEmpty(object)) {
    if (!isEmpty(object.line1) && showLineZip) {
      if (!isEmpty(address))
        address = address + ", " + object.line1
      else
        address = object.line1
    }

    if (!isEmpty(object.locationDetails)) {
      if (!isEmpty(object.locationDetails.name))
        if (!isEmpty(address))
          address = address + ", " + object.locationDetails.name
        else
          address = object.locationDetails.name
    }
    if (!isEmpty(object.stateDetails)) {
      if (!isEmpty(object.stateDetails.name))
        if (!isEmpty(address))
          address = address + ", " + object.stateDetails.name
        else
          address = object.stateDetails.name
    }
    if (!isEmpty(object.countryDetails)) {
      if (!isEmpty(object.countryDetails.name))
        if (!isEmpty(address))
          address = address + ", " + object.countryDetails.name
        else
          address = object.countryDetails.name
    }
    if (!isEmpty(object.zipcode) && showLineZip) {
      if (!isEmpty(address))
        address = address + ", " + object.zipcode
      else
        address = object.zipcode
    }
  }
  return address;
}


export function getFormatedDateTime(dateTime) {
  return dateTime && moment(dateTime).format("MMM DD, YYYY hh:mm A") ? moment(dateTime).format("MMM DD, YYYY hh:mm A") : '--';
}

export function getFormatedMonthYear(dateTime) {
  return dateTime && moment(dateTime).format("MMM 'YY") ? moment(dateTime).format("MMM 'YY") : '--';
}
export function getFormatedDate(dateTime) {
  return dateTime && moment(dateTime).format("MMM DD, YYYY") ? moment(dateTime).format("MMM DD, YYYY") : '--';
}
export function getFormatedMonthDate(dateTime) {
  return dateTime && moment(dateTime).format("MMM, DD") ? moment(dateTime).format("MMM, DD") : '--';
}

export function getCurrentDateTime(){
  return moment().format()
}

export function currentTimeZone(){
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function getCurrentDate(){
  return moment();
}
export function getRankingClass(rank) {
  if (rank) {
    return rank == 1 ? 'one'
      : rank == 2 ? 'two'
        : rank == 3 ? 'three'
          : rank == 4 ? 'four'
            : rank == 5 ? 'five' : ''
  }
  return "";
}

export function getPreferedCountries(countries) {
  const dataArray = countries
  const topShortNames = ["US", "IN"];
  dataArray.sort((a, b) => {
    const aIndex = topShortNames.indexOf(a.shortName);
    const bIndex = topShortNames.indexOf(b.shortName);
    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex;
    }
    if (aIndex !== -1) {
      return -1;
    }
    if (bIndex !== -1) {
      return 1;
    }
    return 0;
  });
  return dataArray;
}

export function getTrimmedContent(content) {

  // Strip HTML tags using a temporary element
  const tempElement = document.createElement("div");
  tempElement.innerHTML = content;
  const plainText = tempElement.textContent || tempElement.innerText || "";
  // Trim the content to 200 characters and add "..." if needed
  const trimmedText = plainText.length > 100 ? plainText.slice(0, 100) + "..." : plainText;
  return trimmedText;

}


export function isMatchplicityDomain() {
  return true;
  // return  ['enterprise.saas.matchplicity.com'].findIndex((item) => item == window.location.host) > -1;
 // return  ['localhost:3000','enterprise.saas.matchplicity.com'].findIndex((item) => item == window.location.host) > -1;
}


export function getPlatformName() {
  return 'Matchplicity';
  // return  ['localhost:3000','enterprise.saas.matchplicity.com'].findIndex((item) => item == window.location.host) > -1?'Matchplicity':'Matchplicity';
}

export function updateCommunityId(communityId) {
  const userInfo = JSON.parse(localStorage.getItem('userinfo'))
  userInfo.communityId = communityId
  localStorage.setItem("userinfo", JSON.stringify(userInfo))
  return "";
}

export function getLogoLetters(name){
  let names = name?.split(/[^a-zA-Z]+/)
  if(names?.length > 1 && names[0] && names[1]){
    return names[0]?.slice(0,1) + names[1]?.slice(0,1)
  }else{
    return names?.[0]?.slice(0,2)
  }
}
export function getEmpCanAccess(){
  const userInfo = JSON.parse(localStorage.getItem('userinfo'))
  if(((userInfo?.accessPermission == 0 || ((( !(Object.keys(userInfo).indexOf('accessPermission') > -1)) || userInfo?.accessPermission == 1) && userInfo?.paymentDone == true)) &&  [5,6].indexOf(userInfo?.roleId) > -1)){
    return true
  }
  return false;
}

export function canAccess() {
  const userInfo = JSON.parse(localStorage.getItem('userinfo'))
  if( ((userInfo?.accessPermission == 0 || ((!(Object.keys(userInfo).indexOf('accessPermission') > -1) || userInfo?.accessPermission == 1) && userInfo?.paymentDone == true))) || (!userInfo?.communityPaymentRequired && ([3,4].indexOf(userInfo?.roleId) > -1))){
    return true
  }
  return false;
}

export function getBaseUrl(){
 if (process.env.NODE_ENV === 'production') {
  return 'https://enterprise.saas.matchplicity.com'
  } else {
  return 'http://192.168.100.108:3000';
  }  
}

export function getFullName(firstName="", lastName=""){
  let name = []
  if(firstName){
    name.push(firstName)
  }
  if(lastName){
    name.push(lastName)
  }
  return name.join(' ')
}